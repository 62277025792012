var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boasVindas"},[(!_vm.configBoasVindasTextos)?_c('Loading'):_vm._e(),(_vm.configBoasVindasTextos)?_c('div',[_c('HeaderVue',{attrs:{"logo":_vm.configBoasVindas?.logo_hotel}}),_c('div',{staticClass:"containerDescricao"},[_c('div',{staticClass:"bolas"},[_c('div',{class:this.idioma === 'pt-BR'
              ? 'bola bola-brasil selected width'
              : 'bola bola-brasil',on:{"click":() => _vm.selecionarIdioma('pt-BR')}},[_c('img',{attrs:{"src":"https://cdn.countryflags.com/thumbs/brazil/flag-round-250.png"}})]),_c('div',{class:this.idioma === 'en'
              ? 'bola bola-eua selected width'
              : 'bola bola-brasil',on:{"click":() => _vm.selecionarIdioma('en')}},[_c('img',{attrs:{"src":"https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png"}})]),_c('div',{class:this.idioma === 'es'
              ? 'bola bola-espanha selected width'
              : 'bola bola-brasil',on:{"click":() => _vm.selecionarIdioma('es')}},[_c('img',{attrs:{"src":"https://cdn.countryflags.com/thumbs/spain/flag-round-250.png"}})])]),(!_vm.configBoasVindas?.imagem_hotel)?_c('div',{staticClass:"loading",staticStyle:{"margin-top":"20px","border-radius":"10px","width":"100%","max-width":"500px","height":"300px"}},[_c('div',{staticClass:"c-loader"})]):_vm._e(),(_vm.configBoasVindas?.imagem_hotel)?_c('img',{staticStyle:{"margin-top":"20px","border-radius":"10px","width":"100%","max-width":"500px"},attrs:{"src":this.page === 1
            ? _vm.configBoasVindas?.imagem_hotel
            : _vm.configBoasVindas?.imagem_hotel_2,"alt":"imagem hotel","id":"imgBoasVindas1"}}):_vm._e(),_c('div',{staticStyle:{"word-wrap":"break-word","max-width":"500px"}},[_c('h2',[_vm._v(" "+_vm._s(this.page === 1 ? _vm.configBoasVindasTextos?.titulo_1 : _vm.configBoasVindasTextos?.titulo_2)+" ")]),_c('p',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(this.page === 1 ? _vm.configBoasVindasTextos?.descricao_1 : _vm.configBoasVindasTextos?.descricao_2)+" ")])]),_c('div',{staticClass:"containerButtonProximo"},[(!_vm.tela_2)?_c('div',{staticStyle:{"background-color":"#156cf7","border-radius":"50%","width":"8px","height":"8px"}}):_vm._e(),(_vm.tela_2)?_c('img',{attrs:{"src":this.page === 1
              ? '/BoasVindas/ARRASTAR.svg'
              : '/BoasVindas/ARRASTAR2.svg',"id":"imgBoasVindas1"}}):_vm._e(),(this.page === 2 && _vm.tela_2)?_c('button',{staticClass:"buttonProximo",attrs:{"type":"button"},on:{"click":() => this.voltar()}},[_vm._v(" "+_vm._s(_vm.$t("webcheckin.Boas_vindas.voltar"))+" ")]):_vm._e(),_c('button',{staticClass:"buttonProximo",attrs:{"id":"btn-iniciar-checkin","type":"button"},on:{"click":() => this.proximaPagina()}},[_vm._v(" "+_vm._s(this.page === 1 && _vm.tela_2 ? _vm.$t("webcheckin.Boas_vindas.proximo") : _vm.$t("webcheckin.Boas_vindas.iniciarCheckin"))+" ")])])])],1):_vm._e(),(_vm.configBoasVindas)?_c('FooterVue'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }